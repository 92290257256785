import type { VideoSection } from "@/modules/corporate/types/sections"
import type { FunctionComponent } from "react"

import { Layout } from "@jobteaser/ui-kit/components/Layout/Layout"

import { MediaVideo } from "@/modules/corporate/components/MediaVideo/MediaVideo"
import { Title } from "@/modules/corporate/components/Sections/Title/Title"

export type VideoPropsType = {
  section: VideoSection
}

export const Video: FunctionComponent<VideoPropsType> = ({ section }) => {
  return (
    <Layout hasTopPadding hasBottomPadding>
      <Layout.Column>
        {!!section.title && <Title title={section.title} tag="h2" isCentered />}
        <MediaVideo title={section.title} url={section.url} />
      </Layout.Column>
    </Layout>
  )
}
